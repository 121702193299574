import React from 'react';
import LabelInput from '../../components/Form/LabelInput';
import PrimaryCard from '../../components/Cards/PrimaryCard';
import ImageUpload from '../../components/Form/Upload';
import SelectWithUpload from '../../components/Form/SelectWithUpload';
import { indianStates, indianCities } from '../../utils';
import { CloseCircleFilled } from '@ant-design/icons';
import Button from '../../components/Buttons/Button';
import { CUSTOMER_TYPE } from '../../constants/constants';

export default function CreateCustomer({ customerType, deleteCustomer, customerValues, handleChange }) {
  return (
    <PrimaryCard className="p-10 space-y-10 w-full relative">
      {deleteCustomer && (
        <Button variant="secondary" className="absolute py-1 ml-auto right-2 top-3" onClick={deleteCustomer}>
          <CloseCircleFilled className="mr-1"/> Remove
        </Button>
      )}
      <div className="flex gap-5 flex-col  md:flex-row">
        <LabelInput type="text" label="First Name" rootClassName="w-full sm:w-1/2 md:w-1/3"
          placeholder="Enter First Name" value={customerValues?.first_name ?? ''}
          onChange={(e) => handleChange(customerType, 'first_name', e.target.value)}
          required
        />
        <LabelInput type="text" label="Last Name" rootClassName="w-full sm:w-1/2 md:w-1/3"
          placeholder="Enter Last Name" value={customerValues?.last_name ?? ''}
          onChange={(e) => handleChange(customerType, 'last_name', e.target.value)}
          required
        />
        <LabelInput type="text" label="Customer Type" rootClassName="w-full sm:w-1/2 md:w-1/3"
          inputClassName="capitalize" placeholder="Choose Customer Type" value={customerType?.split('_')?.join(' ')} 
          disabled required
        />
      </div>
      <div className="flex gap-5 flex-col md:flex-row">
        <LabelInput type="numberText" label="Mobile Number" placeholder="Enter mobile number"
          rootClassName="w-full sm:w-1/3"
          value={customerValues?.mobile_number ?? ''} required maxLength={10} minLength={10}
          onChange={(value) => handleChange(customerType, 'mobile_number', value)}
        />
        <LabelInput type="email" label="Mail ID" rootClassName="w-full sm:w-1/3"
          placeholder="Enter Customer Mail ID" value={customerValues?.email ?? ''}
          onChange={(e) => handleChange(customerType, 'email', e.target.value)}
          required
        />
        {
          customerType === CUSTOMER_TYPE.primary_customer ? (<LabelInput type="text" label="customer source" rootClassName="w-full sm:w-1/3"
          placeholder="Enter Source" value={customerValues?.customerSource ?? ''}
          onChange={(e) => handleChange(customerType, 'customerSource', e.target.value)}
        />) : null
        }
      </div>
      <div className="flex flex-col space-y-2">
        <p className="label">Location</p>
        <LabelInput type="text" placeholder="Address line 1" value={customerValues?.addr_line_1 ?? ''}
          onChange={(e) => handleChange(customerType, 'addr_line_1', e.target.value)}
          required
        />
        <LabelInput type="text" placeholder="Address line 2" value={customerValues?.addr_line_2 ?? ''}
          onChange={(e) => handleChange(customerType, 'addr_line_2', e.target.value)}
        />
        <div className="flex gap-5 flex-wrap">
          <LabelInput type="select" rootClassName="w-full sm:w-1/2 md:w-1/4"
            placeholder="City" value={customerValues?.city ?? ''}
            options={indianCities}
            onChange={(e) => handleChange(customerType, 'city', e.target.value)}
            required
          />
          <LabelInput type="select" rootClassName="w-full sm:w-1/2 md:w-1/3"
            placeholder="State" value={customerValues?.state ?? ''}
            onChange={(e) => handleChange(customerType, 'state', e.target.value)}
            options={indianStates} required
          />
          <LabelInput type="numberText" rootClassName="w-full sm:w-1/2 md:w-auto"
            placeholder="Pincode" value={customerValues?.pincode ?? ''}
            onChange={(e) => handleChange(customerType, 'pincode', e)}
            required maxLength={6} minLength={6}
          />
        </div>
      </div>
      <div className="flex gap-5 flex-col md:flex-row">
        <SelectWithUpload label="Address Proof" placeholder="Choose Proof" rootClassName="w-1/2"
          options={['Aadhar Card', 'Pass book']}
          onChange={(value) => { handleChange(customerType, 'address_proof', value); }}
        />
        <SelectWithUpload label="ID Proof" rootClassName="w-1/2"
          placeholder="Choose Proof" options={['Aadhar Card', 'Voter ID']}
          onChange={(value) => { handleChange(customerType, 'id_proof', value); }}
        />
      </div>
      <ImageUpload label="recent photo" placeholder="Upload" rootClassName="w-full md:w-3/4 lg:w-2/5"
        value={customerValues?.recent_photo ?? []}
        onChange={(value) => handleChange(customerType, 'recent_photo', value[0])}
        maxFiles={1} accept=".jpg, .jpeg, .png"
      />
    </PrimaryCard>
  );
}
